<script setup lang="ts">
import type { SearchBrandResultProps } from './SearchBrandResult.props.ts'

const props = withDefaults(defineProps<SearchBrandResultProps>(), {
  total: 0,
  isVisible: true,
  page: 0,
  xseQuery: false,
})

const emit = defineEmits<{
  (e: 'load-more'): void
  (e: 'set-pagination', brand: string, page: number): void
}>()

const { ts } = useI18n()
const { dispatchInternalSearchExpansion } = useGAInternalSearchEvents()
const PREVIEW_ITEMS = 4

const showPreview = ref<boolean>(true)

const items = computed(() => {
  if (showPreview.value) return props.products.slice(0, PREVIEW_ITEMS)
  else return props.products
})
const handlePreview = () => {
  emit('load-more')
  dispatchInternalSearchExpansion(props?.searchResult ?? '', props?.id)

  showPreview.value = !showPreview.value
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="text-book-6 flex">
      <span class="flex grow items-center">
        <!-- @vue-skip megaLogoMap is not auto importing-->
        <component
          :is="menuLogoMap[id as MenuLogoType]"
          class="mr-2 inline-block h-5 w-5"
        />
        <h5 class="inline uppercase">
          <span>{{ name }}</span> <span v-if="total">({{ total }})</span>
        </h5>
      </span>
      <AtomsCta
        v-if="products.length > PREVIEW_ITEMS"
        :aria-label="
          showPreview
            ? $ts('aria-labels.search.showAll')
            : $ts('aria-labels.search.showLess')
        "
        anatomy="link"
        @click="handlePreview()"
      >
        <template #label>
          {{
            showPreview
              ? ts('search.results.showAll')
              : ts('search.results.showLess')
          }}
        </template>
      </AtomsCta>
    </div>
    <div>
      <ul
        :class="[
          'search-grid grid grid-cols-2 gap-[1px] md:grid-cols-3 lg:grid-cols-4',
          { 'search-grid--preview': showPreview },
        ]"
      >
        <li v-for="(item, index) in items" :key="item?.objectID ?? index">
          <WrapperProductTile :product="item">
            <template #default="{ product }">
              <UIProductTile
                xse-type="rec"
                :product="{
                  ...product,
                  linkToProduct: `${product.linkToProduct}?xse_prod_code=${product.sku}&xse=${predictionId}`,
                }"
                :xse-query="xseQuery ? searchResult : null"
                :xse-prod-code="product.sku"
                :show-cta-actions="false"
                class="search-grid__item col-span-1 h-full"
              />
            </template>
          </WrapperProductTile>
        </li>
      </ul>
      <div
        v-if="!showPreview && items.length < total"
        class="py-lg flex justify-center"
      >
        <AtomsCta
          :loading="isLoading"
          @click-handler="emit('set-pagination', id, page + 1)"
        >
          <template #label>
            {{
              ts('search.results.showMore', {
                brand: name,
                current: items.length,
                total,
              })
            }}
          </template>
        </AtomsCta>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.search-grid {
  &__item {
    box-shadow: 0 0 0 1px var(--colorStrokeCardDefault);

    .outline-stroke-cards-hover {
      position: relative;

      &:hover {
        outline: none !important;

        &::after {
          content: '';
          position: absolute;
          inset: 0;
          border: 1px solid var(--colorStrokeCardHover);
          z-index: 10;
          pointer-events: none;
        }
      }
    }
  }

  &--preview {
    .search-grid__item {
      display: none;

      &:nth-child(-n + 2) {
        display: block;
      }

      @screen md {
        &:nth-child(-n + 3) {
          display: block;
        }
      }

      @screen lg {
        &:nth-child(-n + 4) {
          display: block;
        }
      }
    }
  }
}
</style>
