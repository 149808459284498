export const useVisenzeImageSearch = () => {
  const vSearchActive = useState('visual-search-status', () => false)
  const uid = computed(
    () =>
      (window &&
        window.localStorage &&
        window.localStorage.getItem('va-uid')) ||
      (() => {
        let e = new Date().getTime()
        return 'xxxxxxxx.xxxx.4xxx.yxxx.xxxxxxxxxxxx'.replace(/[xy]/g, x => {
          const t = (e + 16 * Math.random()) % 16 | 0
          e = Math.floor(e / 16)
          return (x === 'x' ? t : (3 & t) | 8).toString(16)
        })
      })()
  )

  return {
    vSearchActive,
    uid,
  }
}
