<script setup lang="ts">
const emit = defineEmits<{
  'click-on-search-term': [searchTerm: string]
}>()

const { deleteRecentSearch, cookiesRecentSearches } = useRecentSearches()
</script>

<template>
  <div class="mt-16">
    <span class="text-book-6">{{ $ts('search.recentSearches') }}</span>
    <ul>
      <li
        v-for="(searchTerm, i) in cookiesRecentSearches"
        :key="`${searchTerm}_${i}`"
        class="mt-3 flex items-center"
      >
        <DefaultIconsRecentlyViewed class="mr-2 h-6 w-6" aria-hidden="true" />
        <button
          class="text-book-6 mr-1 block"
          @click="emit('click-on-search-term', searchTerm)"
        >
          {{ searchTerm }}
        </button>
        <button
          class="text-book-6 mr-1 block"
          :aria-label="$ts('aria-labels.deleteTerm', { term: searchTerm })"
          @click="deleteRecentSearch(i)"
        >
          <DefaultIconsClose class="h-4 w-4" aria-hidden="true" />
        </button>
      </li>
    </ul>
  </div>
</template>
