<script setup lang="ts" generic="Value">
import type { InputsCheckboxWithSlotProps } from './InputsCheckboxWithSlot.props'

const props = defineProps<InputsCheckboxWithSlotProps>()
const model = defineModel<string[]>()
const checked = computed(() => {
  if (model.value?.length === 0) {
    return false
  }
  return model?.value?.filter(el => el === props.value).length
})

defineEmits<{
  'update:modelValue': [value: Value]
}>()
</script>

<template>
  <div class="relative flex w-full flex-col">
    <input
      :id="id"
      v-model="model"
      type="checkbox"
      :value="value"
      :name="name"
      :checked="!!checked"
      :disabled="disabled"
      class="peer absolute z-0 opacity-0"
    />

    <label
      :for="id"
      class="*:ring-primitives-blue relative z-10 flex w-full cursor-pointer items-center gap-3 *:ring-offset-2 peer-focus-visible:*:ring-1"
      :class="{ '!cursor-not-allowed': disabled }"
    >
      <span class="flex items-center gap-3">
        <div
          class="flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center border"
          :class="{
            'bg-neutral-black': checked,
            'opacity-20': disabled,
            'border-[red]': error,
          }"
        >
          <DefaultIconsCheck
            v-if="checked"
            class="text-neutral-ultralight h-full w-full"
            aria-hidden="true"
          />
        </div>
        <div class="flex text-center">
          <label
            :for="id"
            class="cursor-pointer"
            :class="[
              {
                '!cursor-not-allowed line-through': disabled,
              },
            ]"
          >
            <slot name="label" />
          </label>
        </div>
      </span>
    </label>
  </div>
</template>
