export const reorderKeys = (
  obj: Record<string, Record<string, number>>,
  config: string[]
): Record<string, Record<string, number>> => {
  const orderedObj: Record<string, Record<string, number>> = {}

  for (const key of config) {
    if (key in obj) {
      orderedObj[key] = obj[key]
    }
  }

  return orderedObj
}
