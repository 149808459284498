<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { GiftDetailsSideSlideProps } from './GiftDetailsSideSlide.props'
const { gift } = defineProps<GiftDetailsSideSlideProps>()
const { data } = await useBlockText(gift?.metadata?.delivery_key)
</script>

<template class="">
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.giftDetailsSideSlide"
    :header-props="{
      titleText: data?.content?.title ?? $ts('miniCart.giftSideTitle'),
    }"
  >
    <template #body>
      <div class="bg-neutral-white mx-auto flex flex-col">
        <NuxtPicture
          :src="gift?.image_url!"
          :alt="gift?.name ?? ''"
          provider="cloudinary"
          class="aspect-pdp-image object-contain"
        />

        <div class="relative flex flex-col gap-y-2 pt-10">
          <p class="text-book-6">{{ gift?.name }}</p>
          <UtilsMarkdown
            v-if="data && data.content.text"
            container="p"
            class="text-light-6"
            :content="data.content.text"
          />
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
