<script lang="ts" setup>
import type { SearchFiltersPanelProps } from './SearchFiltersPanel.props.ts'

defineProps<SearchFiltersPanelProps>()
defineEmits(['applyFilters'])

const { selectedFilters, formatFilterLabel } = useSearchFilters()
</script>

<template>
  <div class="bg-primitives-off-white mb-6 px-10 pb-8 pt-6">
    <div>
      <UIDropdown
        v-for="(filter, filterKey) in filters"
        :key="filterKey"
        :title="$ts(`search.filters.list.${filterKey.toString()}`)"
        class="border-primitives-grey-50 border-t first-of-type:border-none"
      >
        <ul class="grid grid-cols-1 md:grid-cols-3">
          <li
            v-for="(resultsNumber, resultKey) in filter"
            :key="`${resultKey}_${resultsNumber}`"
            class="mb-6 flex gap-1"
          >
            <InputsCheckboxWithSlot
              v-if="resultsNumber > 0"
              :id="`${resultKey}_${resultsNumber}`"
              v-model="selectedFilters[filterKey.toString()]"
              :name="`${resultKey} (${resultsNumber})`"
              :value="resultKey"
              is-select-size-component
            >
              <template #label>
                <div class="flex justify-center">
                  {{ formatFilterLabel(resultKey) }}
                  <div
                    class="text-light-7 text-primitives-grey-200 ml-1 flex w-fit items-center"
                  >
                    ({{ resultsNumber }})
                  </div>
                </div>
              </template>
            </InputsCheckboxWithSlot>
          </li>
        </ul>
      </UIDropdown>
    </div>
    <div class="flex justify-center">
      <AtomsCta
        :icon-show-default="false"
        anatomy="primary"
        class="mt-8"
        @click="$emit('applyFilters')"
      >
        <template #label>
          {{ $ts('search.filters.apply') }}
        </template>
      </AtomsCta>
    </div>
  </div>
</template>
