<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { InputsSearchProps } from './InputsSearch.props.ts'

const emit = defineEmits<{
  (e: 'open-visual-finder'): void
  (e: 'reset'): void
  (e: 'focus'): void
}>()

const props = withDefaults(defineProps<InputsSearchProps>(), {
  showVisualFinderIcon: true,
  variant: 'box',
})

const modelValue = defineModel<string>({ default: '' })

const focusFormKitInput = () => {
  const formKitElement = document.getElementById(props.name)
  formKitElement?.focus()
}

const clear = () => {
  modelValue.value = ''
  emit('reset')
}

onMounted(() => {
  if (props.autoFocus) {
    setTimeout(() => {
      const input = document.getElementById(props.name)
      if (input) input.focus()
    }, 500)
  }
})
</script>
<template>
  <div @click="focusFormKitInput">
    
<FormKitLazyProvider config-file="true">
<FormKit
      :id="name"
      v-model="modelValue"
      :name="name"
      :placeholder="placeholder"
      type="search"
      :outer-class="{
        'flex items-center bg-primitives-grey-50 px-sm py-3 cursor-text':
          variant === 'box',
      }"
      :inner-class="{
        'border-none w-full !pb-0 !pt-0': variant !== 'underline',
      }"
      wrapper-class="w-full"
      :delay="1000"
      autocomplete="off"
      @focus="() => $emit('focus')"
    >
      <template #suffixIcon>
        <div class="flex shrink-0 items-center gap-2">
          <button
            v-if="modelValue"
            type="button"
            :aria-label="$ts('aria-labels.clearSearchInput')"
            @click="clear"
          >
            <DefaultIconsClose aria-hidden="true" class="h-5 w-5" />
          </button>
          <span
            v-if="modelValue && showVisualFinderIcon"
            class="text-primitives-grey-200 text-book-6 inline-block"
          >
            |
          </span>
          <button
            v-if="showVisualFinderIcon"
            type="button"
            class="flex items-center gap-3"
            :aria-label="$ts('aria-labels.visualSearch')"
            @click.prevent.stop="$emit('open-visual-finder')"
          >
            <DefaultIconsCamera aria-hidden="true" class="h-5 w-5" />
          </button>
        </div>
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>

<style lang="scss">
input[type='search']::-webkit-search-cancel-button {
  display: none;
}
</style>
