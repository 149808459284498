<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { HotspotModalProps } from '@integration-layer/components/Hotspot/HotspotModal.props'
import { useHotspotModal } from '@integration-layer/composables/useHotspotModal'

const { quickBuyProps: props, quickBuyEvents: events } =
  useUIQuickBuyPopUpSlide()

const { isDialogOpen: isContactUsSideSlideOpen } = useDialog(
  SIDEBAR_IDS.cartContactUsSideSlide
)
const { isDialogOpen: isGiftDetailSideSlideOpen } = useDialog(
  SIDEBAR_IDS.giftDetailsSideSlide
)
const { giftDetailSideSlideState, setGiftDetailSideSlideState } =
  useGiftDetailSideSlide()
const { isDialogOpen: isHotspotOpen } = useDialog(
  SIDEBAR_IDS.hotspotModalProduct
)

const { hotspotModalState, setHotspotModalState } = useHotspotModal()

watch(
  () => isHotspotOpen.value,
  isOpen => {
    if (!isOpen) setHotspotModalState(undefined)
  }
)

watch(
  () => isGiftDetailSideSlideOpen.value,
  isOpen => {
    if (!isOpen) setGiftDetailSideSlideState(undefined)
  }
)
</script>
<template>
  <div>
    <UIQuickBuyPopUpSlide
      v-if="props?.product"
      v-bind="props"
      :id="SIDEBAR_IDS.uiQuickBuyPopupSlide"
      :key="props.product.skuCode"
      @save-changes="events?.saveChanges"
    />
    <NewsletterSidebar />
    <SearchDialog @wheel.stop="''" @scroll.stop="''" />
    <CartContactUsSideSlide />
    <BookAnAppointmentSidebar v-if="!isContactUsSideSlideOpen" />
    <template v-if="giftDetailSideSlideState">
      <GiftDetailsSideSlide
        v-if="isGiftDetailSideSlideOpen"
        :gift="giftDetailSideSlideState"
      />
    </template>
    <template v-if="hotspotModalState">
      <HotspotModal v-if="isHotspotOpen" v-bind="hotspotModalState" />
    </template>
  </div>
</template>
