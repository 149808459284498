export const useRecentSearches = () => {
  const cookiesRecentSearches = useCookie<string[]>('recent-searches', {
    default: () => [],
    maxAge: 43200,
  })

  const isSearchValueInRecentSearches = (searchValue: string) => {
    return cookiesRecentSearches.value.includes(searchValue)
  }

  const saveRecentSearch = (searchValue: string) => {
    if (isSearchValueInRecentSearches(searchValue)) return
    if (cookiesRecentSearches.value.length >= 3)
      cookiesRecentSearches.value.pop()

    cookiesRecentSearches.value.unshift(searchValue)
  }

  const deleteRecentSearch = (index: number) => {
    if (cookiesRecentSearches.value)
      cookiesRecentSearches.value.splice(index, 1)
  }

  return {
    cookiesRecentSearches,
    saveRecentSearch,
    deleteRecentSearch,
  }
}
