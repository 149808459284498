<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { getNode } from '@formkit/core'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const newsletterSidebar = SIDEBAR_IDS.newsletterSidebar
const loginSidebar = SIDEBAR_IDS.loginSidebar
const { openDialog, closeDialog, isDialogOpen } = useDialog(
  SIDEBAR_IDS.newsletterSidebar
)
const { getBrandAcronym, getNewsletterImage } = await useBrandsSettings()
const { isLogged } = useLogin()
const { isHome, privacyPolicy } = useRouteHelper()
const { allBrands } = await useBrands()
const { isGlobalWebsite, currentWebsite } = useWebsite()
const { customer, updateCustomerMetadata } = await useCustomer()
const { $ts } = useI18n()

const { dispatchActionNewsletterEvent } = useGAActionEvents()

const {
  salesforceId,
  step,
  email: newsletterEmail,
  setNewsletterEmail,
  updateNewsletterUser,
  setCurrentStep,
  openNewsletterSidebar,
} = await useNewsletter()

const loading = ref(false)

const notification = ref(false)

const removeNotificationTimeout = ref<ReturnType<typeof setTimeout> | null>(
  null
)

const brandsAcronyms = computed(() =>
  Array.from(
    new Set(
      brands
        .map(item => getBrandAcronym(item))
        .filter((value): value is string => value !== undefined)
    )
  )
)

const defaultBrandPreferences = computed(() => {
  if (isGlobalWebsite.value) {
    return brandsAcronyms.value
  }
  const brandAcronym = getBrandAcronym(currentWebsite.value)
  return brandAcronym ? [brandAcronym] : []
})

watch(currentWebsite, () => {
  resetBrandPreferences()
})

const resetBrandPreferences = () => {
  if (isGlobalWebsite.value) {
    formData.value.brands_preferences = brandsAcronyms.value
  } else {
    formData.value.brands_preferences = [
      getBrandAcronym(currentWebsite.value) ?? '',
    ]
  }
}

const newsletterImageSrc = computed(() => {
  return isGlobalWebsite.value
    ? ''
    : getNewsletterImage(currentWebsite.value)?.secure_url
})

const brandsChoose = computed(
  () =>
    allBrands?.value?.content?.brands?.map(el => ({
      label: el.label!,
      value: getBrandAcronym(el.value!),
    })) ?? []
)

const channelsPreferences = computed<string[]>(() => {
  if (!isLogged.value) {
    return ['email']
  }

  const customerPreferences = customer?.value?.channelsPreference

  if (!customerPreferences) {
    return ['email']
  }

  return customerPreferences.includes('email')
    ? customerPreferences
    : [...customerPreferences, 'email']
})

const formData = ref<NewsletterFormData>({
  marketing_authorization: true,
  brands_preferences: defaultBrandPreferences.value ?? [],
  channels_preferences: channelsPreferences.value,
  prefix: customer?.value.prefix ?? '',
  phoneNumber: customer?.value.phoneNumber ?? '',
})

const selectAllBrands = () => {
  formData.value.brands_preferences = brandsChoose.value
    .map(brand => brand.value)
    .filter((value): value is string => value !== undefined)
}

const isAllBrandsSelected = computed(() => {
  const totalbrands = brandsChoose?.value?.length
  const selectedBrands = formData.value.brands_preferences?.length
  return totalbrands === selectedBrands
})

const undoRemoveNotification = () => {
  notification.value = false
  if (removeNotificationTimeout.value) {
    clearTimeout(removeNotificationTimeout.value)
    removeNotificationTimeout.value = null
  }
}

const updateEmailValue = (newEmail: string) => {
  dispatchActionNewsletterEvent(currentWebsite.value)
  setNewsletterEmail(newEmail)
}

const formId = 'newsletter-subscribe-form'
const recaptchaErrorMsg = ref<boolean>(false)

const handleConfirm = () => {
  const newsletterForm = getNode(formId)
  newsletterForm?.submit()
}

const stepsObj = ref([
  {
    id: NEWSLETTER_STEP.SUBSCRIBE,
    title: $ts('newsletter.title'),
    label: $ts('newsletter.subscribe'),
    primaryClickHandle: async () => {
      const { checkTokenReCAPTCHA } = useRecaptchaSite()

      await checkTokenReCAPTCHA('newsletterFirstStep')
      openNewsletterSidebar(newsletterEmail.value)
    },
  },
  {
    id: NEWSLETTER_STEP.PREFERENCE,
    title: $ts('newsletter.title'),
    label: $ts('newsletter.confirmationPreference'),
    primaryClickHandle: async () => {
      loading.value = true
      await handleSubmit()
      loading.value = false
    },
  },
  {
    id: NEWSLETTER_STEP.THANK_YOU,
    title: $ts('newsletter.thankYouTitle'),
    label: $ts('newsletter.close'),
    primaryClickHandle: () => {
      closeDialog()
      setCurrentStep(NEWSLETTER_STEP.SUBSCRIBE)
    },
  },
  {
    id: NEWSLETTER_STEP.LOGIN,
    title: $ts('newsletter.title'),
    label: $ts('newsletter.login'),
    primaryClickHandle: () => {
      closeDialog(newsletterSidebar)
      openDialog(loginSidebar)
      localStorage.setItem('nl-login-tmp', 'true')
    },
  },
])

const handleSubmit = async () => {
  recaptchaErrorMsg.value = false
  try {
    const { checkTokenAndVerify } = useRecaptchaSite()
    const check = await checkTokenAndVerify('newsletterSecondStep')
    if (!check.resultToken || !check.resultVerifyReCaptchaToken) {
      recaptchaErrorMsg.value = true
      return
    }
    const sfId = salesforceId.value
    const email = newsletterEmail.value
    if (formData?.value?.channels_preferences?.length === 0) {
      return
    }
    notification.value = true
    removeNotificationTimeout.value = setTimeout(() => {
      notification.value = false
    }, 7000)
    if (isLogged.value) {
      await updateCustomerMetadata!(formData.value, false)
    } else {
      await updateNewsletterUser(email, sfId, formData.value)
    }
    setCurrentStep(NEWSLETTER_STEP.THANK_YOU)
  } catch (error) {
    console.error(error)
  }
}

const getStep = (step: string) => {
  return stepsObj.value.find(el => el.id === step)
}

// closing the newsletter sidebar will reset the brand preferences
watch(isDialogOpen, () => {
  if (!isDialogOpen.value) {
    resetBrandPreferences()
  }
  if (isLogged.value && customer?.value.brandPreference) {
    formData.value.brands_preferences = customer?.value.brandPreference
  }
})

const getPrimaryClickHandle = computed(() =>
  step.value === NEWSLETTER_STEP.SUBSCRIBE
    ? handleConfirm
    : getStep(step.value)?.primaryClickHandle
)
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<OrganismsSidebarSlide
      :id="newsletterSidebar"
      :header-props="{
        titleText: getStep(step)!.title,
      }"
      :force-global-theme="true"
      :blur-background="isHome"
      :footer-props="{
        primaryText: getStep(step)!.label,
        isPrimaryLoading: false,
      }"
      @primary-click="getPrimaryClickHandle"
    >
      <template #body>
        <div class="flex h-full flex-col justify-between">
          <div v-if="step === NEWSLETTER_STEP.SUBSCRIBE">
            <FormKit
              :id="formId"
              type="form"
              :actions="false"
              :incomplete-message="false"
              @submit="getStep(step)!.primaryClickHandle"
            >
              <InputsTextFields
                type="email"
                name="email"
                autocomplete="email"
                validation="required|email"
                :validation-messages="{
                  required: $ts('generic.requiredEmail'),
                  email: $ts('generic.invalidEmail'),
                }"
                :label="$ts('newsletter.email')"
                show-confirm-button
                @input="updateEmailValue($event)"
                @confirm="handleConfirm"
              />
            </FormKit>
            <p class="text-light-6 pt-6">
              {{ $ts('newsletter.disclaimerSubscribe') }}
              <MoleculesLinkWrapper :to="privacyPolicy" anatomy="link">
                <template #label>
                  {{ $ts('newsletter.privacyPolicy') }}
                </template>
              </MoleculesLinkWrapper>
            </p>
            <div v-if="!isGlobalWebsite && newsletterImageSrc" class="pt-10">
              <NuxtPicture
                :src="newsletterImageSrc"
                :alt="`imageCover_${currentWebsite}`"
              />
            </div>
          </div>
          <div v-if="step === NEWSLETTER_STEP.PREFERENCE">
            <div class="mb-md">
              <p class="text-light-6">
                {{ $ts('newsletter.thankYou') }}
              </p>
              <b>{{ newsletterEmail }} </b>
            </div>
            <OrganismsMessageLong
              v-if="recaptchaErrorMsg"
              :message="$ts('generic.recaptchaError')"
              class="text-primitives-red my-5"
            />
            <FormKit
              id="user-contact-preferences"
              v-model="formData"
              name="user-contact-preferences"
              type="form"
              :actions="false"
              :incomplete-message="false"
              :classes="{ form: 'gap-y-sm md:gap-y-md flex flex-col relative' }"
              @submit="getStep(step)!.primaryClickHandle"
            >
              <UtilsSnackbar
                v-if="notification"
                class="sticky inset-x-0 top-6 z-50"
                is-feedback
                :message="
                  $ts(
                    'myAccount.registration.contactPreferences.contactsUpdated'
                  )
                "
                @update:close="undoRemoveNotification"
              />

              <NewsletterPreferences
                :title="$ts('newsletter.preferencesTitle')"
                :description="$ts('newsletter.preferencesDescription')"
                :is-all-selected="isAllBrandsSelected"
                @select-all="selectAllBrands"
                @deselect-all="
                  () => {
                    formData.brands_preferences = []
                  }
                "
              >
                <FormKit
                  type="myCheckbox"
                  name="brands_preferences"
                  :options="brandsChoose"
                  :validation="
                    formData.brands_preferences ? 'required|min:1' : ''
                  "
                  validation-visibility="submit"
                  :validation-messages="{
                    required: $ts(
                      'contactPreferenceBrandsAndTopicsFieldRequired'
                    ),
                  }"
                  :classes="{
                    option: 'text-medium-5 bg-primitives-white p-sm',
                    options: 'flex flex-col gap-y-sm',
                  }"
                />
              </NewsletterPreferences>
            </FormKit>
          </div>
          <div v-if="step === NEWSLETTER_STEP.THANK_YOU">
            <p class="text-light-6">
              {{ $ts('newsletter.confirmationAndSaveMessage') }}
            </p>
          </div>
          <div v-if="step === NEWSLETTER_STEP.LOGIN">
            <p
              class="text-light-6"
              v-html="$ts('newsletter.mailAlreadyRegistered')"
            ></p>
            <div v-if="!isGlobalWebsite && newsletterImageSrc" class="pt-10">
              <NuxtPicture
                :src="newsletterImageSrc"
                :alt="`imageCover_${currentWebsite}`"
              />
            </div>
          </div>
        </div>
      </template>
    </OrganismsSidebarSlide>
</FormKitLazyProvider>

  </div>
</template>
